import { auth } from '@/configs/firebase.config';
import { getDocRef } from '@/lib/firebase/query';
import { collections } from '@/utils/constants/fb.collections';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { onSnapshot } from 'firebase/firestore';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

export const useAuth = () => {
    const { locale, defaultLocale } = useRouter();
    const t = useTranslations('profile');
    const tError = useTranslations('ErrorMessages');

    const getLocaleContent = (data = {}) => {
        return data[locale] || data[defaultLocale];
    };

    const logout = async (showStatusToast = true) => {
        try {
            const response = await signOut(auth);
            if (showStatusToast) {
                toast.success(t('user_logout_success_txt'));
            }
            return response;
        } catch (error) {
            toast.error(t('user_logout_error_txt'), error);
            return error;
        }
    };

    const listenUserStatus = (userId) => {
        if (!userId) return () => {};

        const userDocRef = getDocRef(collections.customers, userId);

        return onSnapshot(
            userDocRef,
            (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const userData = docSnapshot.data();
                    if (userData.status === 'BLOCKED') {
                        toast.error(tError('userDisabled'));
                        logout(false);
                    }
                    if (userData.isDeleted) {
                        logout(false);
                    }
                }
            },
            (error) => {
                toast.error(t('user_status_check_error_txt'), error);
            }
        );
    };

    const initAuthStateMonitoring = (handleLogout) => {
        let unsubscribeStatus = () => {};

        const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
            unsubscribeStatus();

            if (user) {
                unsubscribeStatus = listenUserStatus(user.uid);
            } else {
                handleLogout(false);
                console.warn('hit handleLogout');
            }
        });

        return () => {
            unsubscribeAuth();
            unsubscribeStatus();
        };
    };

    return {
        getLocaleContent,
        logout,
        initAuthStateMonitoring,
    };
};
