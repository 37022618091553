import Carousel from '@/components/carousel/Carousel';
import Outlets from '@/components/carousel/Outlets';
import GetTheApp from '@/components/getTheApp';
import NotDelivery from '@/components/NotDelivery/NotDelivery';
import PopularDish from '@/components/popularDish';
import FullWidthSlider from '@/components/UI/FullwidthSlider';
import TopSlider from '@/components/UI/topslider.';
import { localStorageKeys } from '@/configs/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import { isGlobalOrder } from '@/utils/validations';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
const Home = () => {
  const router = useRouter();
  const {
    locale
  } = router;
  const t = useTranslations('Home');
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const orderType = actions.getNestedValue(`${localStorageKeys.SELECTED_ADDRESS}.${localStorageKeys.SELECTED_ORDER_TYPE}`);
  return <main data-sentry-component="Home" data-sentry-source-file="index.jsx">
            <NotDelivery data-sentry-element="NotDelivery" data-sentry-source-file="index.jsx" />
            <Carousel data-sentry-element="Carousel" data-sentry-source-file="index.jsx" />
            <FullWidthSlider data-sentry-element="FullWidthSlider" data-sentry-source-file="index.jsx" />
            <div className="categorySlider-container">
                <div className="container">
                    <TopSlider locale={locale} data-sentry-element="TopSlider" data-sentry-source-file="index.jsx" />
                </div>
            </div>
            <PopularDish data-sentry-element="PopularDish" data-sentry-source-file="index.jsx" />
            <div className="featuresSeaction">
                <div className="container">
                    <div className="featuresWrap">
                        <h3>
                            <small>{t('global_label')}</small>
                            {t('global_title')}
                        </h3>
                        <p>{t('global_description')}</p>
                    </div>
                    <div className="featuresWrap">
                        <h3>
                            <small>{t('family_label')}</small>
                            {t('family_title')}
                        </h3>
                        <p>{t('family_description')}</p>
                    </div>
                    <div className="featuresWrap">
                        <h3>
                            <small>{t('popularity_label')}</small>
                            {t('popularity_title')}
                        </h3>
                        <p>{t('popularity_description')}</p>
                    </div>
                </div>
            </div>
            <GetTheApp data-sentry-element="GetTheApp" data-sentry-source-file="index.jsx" />
            {!isGlobalOrder(orderType) && <div className="outlets pt-50" dir={locale === 'ar' ? 'rtl' : 'ltr'}>
                    <div className="container">{<Outlets locale={locale} />}</div>
                </div>}
        </main>;
};
export default Home;