import { localStorageKeys, modalTypeKey } from '@/configs/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import useReduxUtils from '@/hooks/useReduxUtils';
import useUrlParams from '@/hooks/useUrlParams';
import { addLoader, removeLoader } from '@/redux/slices/common';
import { getNearByStores } from '@/service/home.service';
import { debugLog, getStoreHours, isFalsy } from '@/utils';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StaticImg from '../Image/StaticImg';
const NotDelivery = () => {
  const dispatch = useDispatch();
  const t = useTranslations('Home');
  const {
    currentStore
  } = useSelector(store => store?.store) || {};
  const {
    loaders
  } = useSelector(store => store?.common);
  const accountData = useSelector(store => store.account)?.account;
  const orderData = useSelector(store => store.order)?.order;
  const [storesList, setStoresList] = useState([]);
  const {
    addMultipleParams
  } = useUrlParams();
  const {
    getSelectedLocation
  } = useReduxUtils();
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const deviceLocation = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION) || {};
  const selectedAddress = actions.getNestedValue(localStorageKeys.SELECTED_ADDRESS) || {};
  const fetchOutlets = async () => {
    const selectedLocation = getSelectedLocation();
    if (!isFalsy(selectedLocation || {})) {
      try {
        dispatch(addLoader('getNearByStoresData'));
        const response = await getNearByStores({
          ...selectedLocation,
          type: [orderData[localStorageKeys.SELECTED_ORDER_TYPE]]
        });
        setStoresList(response?.data);
        return response;
      } catch (error) {
        console.error('error', error);
      } finally {
        dispatch(removeLoader('getNearByStoresData'));
      }
    }
  };
  useEffect(() => {
    if (!accountData?.deviceLocation?.data || !orderData?.address?.geoData?.coordinates) return;
    fetchOutlets();
  }, [accountData?.deviceLocation?.data, orderData?.address?.geoData?.coordinates, orderData[localStorageKeys.SELECTED_ORDER_TYPE]]);
  if (!deviceLocation.isLocationAllowed && !selectedAddress?.mode) return null;
  let content = null;
  if (loaders.includes('getNearByStoresData') || loaders.includes('gestNearestStoreData')) return null;
  const handleSwitchLocation = () => {
    addMultipleParams({
      [modalTypeKey]: 'locationSelector',
      mode: 'searchLocation'
    });
  };
  debugLog({
    message: 'NotDelivery Details',
    params: {
      cid: currentStore?.id,
      currentStore,
      storesList,
      accountData,
      orderData
    }
  });
  if (isFalsy(currentStore) || isFalsy(storesList) && storesList?.length) {
    content = <div className="not-delivery">
                <StaticImg src={'images/not-delivery.svg'} alt="" />
                <h4>
                    {t('noDeliveryAvailable')}
                    <span>{t('cannotDeliverToTheSelectedLocation')}</span>
                </h4>
                <button onClick={handleSwitchLocation}> {t('switchLocation')}</button>
            </div>;
  }
  if (!isFalsy(currentStore) && !currentStore.flag) {
    content = <div className="not-delivery">
                <StaticImg src={'images/nearby-closed.svg'} alt="" />
                <h4>
                    {t('allNearbyStoresClosed')}
                    <span>{t('storeWillBeOpenAt', {
            openTime: getStoreHours(currentStore)?.open
          })}</span>
                </h4>
                <button onClick={handleSwitchLocation}> {t('switchLocation')}</button>
            </div>;
  }
  if (!content) return;
  return <div className="not-delivery-wrap" data-sentry-component="NotDelivery" data-sentry-source-file="NotDelivery.jsx">
            <div className="container">{content}</div>
        </div>;
};
export default NotDelivery;