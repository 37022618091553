import { assetPath } from '@/lib/helpers/common';
import PropTypes from 'prop-types';
function StaticImg({
  src,
  alt = '',
  ...props
}) {
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img src={assetPath(src)} alt={alt} loading="eager" placeholder="blur" {...props} data-sentry-component="StaticImg" data-sentry-source-file="StaticImg.jsx" />
  );
}
StaticImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};
export default StaticImg;