export const placeSearch = [
    { type: 'rectangle', width: '85%', height: '1rem' },
    { type: 'rectangle', width: '93%', height: '1rem' },
    { type: 'rectangle', width: '100%', height: '1rem' },
];
export const productDetails = [
    { type: 'rectangle', width: '100%', height: '3rem' },
    { type: 'rectangle', width: '100%', height: '2rem' },
    {
        type: 'grid',
        columns: 2,
        rows: 2,
        gap: '1rem',
        children: Array.from({ length: 2 }, () => ({
            type: 'rectangle',
            width: '100%',
            height: '3rem',
        })),
        repeat: 4,
    },
    { type: 'rectangle', width: '100%', height: '2rem' },
];

export const takeAwayDetails = [
    {
        type: 'grid',
        columns: 4,
        gap: '1rem',
        children: [
            {
                type: 'rectangle',
                width: '100%',
                height: '5rem',
            },
            {
                type: 'rectangle',
                width: '100%',
                height: '5rem',
            },
            {
                type: 'rectangle',
                width: '100%',
                height: '5rem',
            },
            {
                type: 'rectangle',
                width: '100%',
                height: '5rem',
            },
        ],
    },
    {
        type: 'grid',
        columns: 3,
        rows: 2,
        gap: '1rem',
        children: [
            {
                type: 'rectangle',
                width: '100%',
                height: '3rem',
            },
            {
                type: 'rectangle',
                width: '100%',
                height: '3rem',
            },
            {
                type: 'rectangle',
                width: '100%',
                height: '3rem',
            },
        ],
        repeat: 6,
    },
];

export const categoryLoader = [
    {
        type: 'grid',
        columns: 5,
        rows: 1,
        gap: '1rem',
        children: Array.from({ length: 5 }, () => ({
            type: 'circle',
            width: '4rem',
            height: '4rem',
        })),
    },
];

export const OrderTrackingDetails = [
    {
        type: 'grid',
        columns: 3,
        rows: 2,
        gap: '1rem',
        children: [
            {
                type: 'rectangle',
                width: '100%',
                height: '3rem',
            },
            {
                type: 'rectangle',
                width: '100%',
                height: '3rem',
            },
            {
                type: 'rectangle',
                width: '100%',
                height: '3rem',
            },
        ],
        repeat: 4,
    },
];

export const OrderTracker = [
    {
        type: 'grid',
        columns: 2,
        rows: 2,
        gap: '1rem',
        children: [
            {
                type: 'rectangle',
                width: '50%',
                height: '3rem',
            },
            {
                type: 'rectangle',
                width: '50%',
                height: '3rem',
            },
        ],
        repeat: 4,
    },
];

export const PaymentDetailsLoader = [
    {
        type: 'grid',
        columns: 2,
        rows: 2,
        gap: '1rem',
        children: [
            {
                type: 'rectangle',
                width: '80%',
                height: '3rem',
            },
            {
                type: 'rectangle',
                width: '20%',
                height: '3rem',
            },
        ],
        repeat: 4,
    },
];

export const threeSquares = [
    {
        type: 'grid',
        columns: 3,
        rows: 1,
        gap: '1rem',
        children: [
            {
                type: 'rectangle',
                width: '100%',
                height: '8rem',
            },
            {
                type: 'rectangle',
                width: '100%',
                height: '8rem',
            },
            {
                type: 'rectangle',
                width: '100%',
                height: '8rem',
            },
        ],
    },
];

export const vpcCard = [{ type: 'rectangle', width: '100%', height: '15rem' }];
export const vpcList = [
    { type: 'rectangle', width: '100%', height: '4rem' },
    { type: 'rectangle', width: '100%', height: '4rem' },
    { type: 'rectangle', width: '100%', height: '4rem' },
    { type: 'rectangle', width: '100%', height: '4rem' },
    { type: 'rectangle', width: '100%', height: '4rem' },
    { type: 'rectangle', width: '100%', height: '4rem' },
    { type: 'rectangle', width: '100%', height: '4rem' },
    { type: 'rectangle', width: '100%', height: '4rem' },
    { type: 'rectangle', width: '100%', height: '4rem' },
];

export const paymentMethodList = [
    { type: 'rectangle', width: '100%', height: '4rem' },
    { type: 'rectangle', width: '100%', height: '4rem' },
    { type: 'rectangle', width: '100%', height: '4rem' },
];
